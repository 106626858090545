import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../components/footer";
import { Link, useLocation } from "react-router-dom";
function Services() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <motion.div
      className="services-wrapper"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <div className="lines"></div>
      <div className="services-wrapper-inner">
        <motion.div
          className="how_head"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <p>Our Services.</p>
        </motion.div>
        <h1>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Unlock
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.06 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Your
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.12 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Business
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.18 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Potential
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.24 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            with
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Our
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.36 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Diverse
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.42 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            IT
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.48 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            and
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.54 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Graphics
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.6 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Services.
          </motion.span>
        </h1>
        <ul>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.64 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Managed IT Services: We offer comprehensive IT support and
            maintenance services to help businesses keep their systems running
            smoothly.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.68 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Cloud Solutions: We can help businesses migrate to the cloud and
            take advantage of the many benefits it offers.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.72 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Cybersecurity: We provide businesses with the latest security
            solutions to protect against cyber threats and keep their data safe.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.76 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Network Design: We can design and implement networks that are
            optimized for businesses' specific needs.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.8 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Website Development: We offer website development and maintenance
            services to help businesses establish a strong online presence.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.84 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Graphic Design: We provide graphic design services, including logo
            design, brochures, business cards, and more.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.88 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            E-commerce Development: We offer e-commerce development services,
            including website design, payment gateway integration, and more.
          </motion.li>
          <motion.li
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.92 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Digital Transformation Consulting: We provide businesses with the
            guidance they need to successfully implement digital transformation
            initiatives.
          </motion.li>
        </ul>
        <div className="allservices-wrapper">
          <motion.div
            className="allservices-wrapper-graphics"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.96 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <h3>Graphics Design</h3>
            <ul>
              <li> Logo Design</li>
              <li> Social Media Creative</li>
              <li> Brochure / Flyer Design</li>
              <li> Packaging Design</li>
              <li> Corporate Identity Design</li>
              <li> Banner / Hoarding Design</li>
            </ul>
          </motion.div>
          <motion.div
            className="allservices-wrapper-web"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <h3>Web Development</h3>
            <ul>
              <li> Corporate Business Website</li>
              <li> Landing Page Development</li>
              <li> E-Commerce Store Development</li>
              <li> Blog Website Development</li>
            </ul>
          </motion.div>
          <motion.div
            className="allservices-wrapper-web"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 1.04 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <h3>UI/UX Design</h3>
            <ul>
              <li> Prototyping</li>
              <li> Web / Application Layout Design</li>
            </ul>
            <h3>Application Development</h3>
            <ul>
              <li> Android / Ios App</li>
              <li> Hybrid App</li>
            </ul>
          </motion.div>
        </div>
        <div className="see-Our-Projects">
          <div className="see-Our-Projects-middle">
            <Link to="/Work">
              <motion.div
                className="see-Our-Projectsinner"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <h4>Take a break and look at our state-of-the-art projects.</h4>
              </motion.div>
              <motion.div
                className="cur-rounddiv"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0 },
                }}
              ></motion.div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default Services;
