import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Contactmian from "../components/contactmian";
import Customerjourney from "../components/customerjourney";
import Footer from "../components/footer";
import Herosection from "../components/herosection";
import Howwehelp from "../components/Howwehelp";
import WorkWith from "../components/WorkWith";

function Home() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="main_container">
      <div className="homepagecontainer">
        <div className="lines"></div>
        <Herosection />
        <Howwehelp />
        <WorkWith />
        <Customerjourney />
        <Contactmian />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
