const Productfile = [
  {
    id: 1,
    DetailesId: "harihar-paper",
    name: "Harihar Papers",
    type: "Web",
    description:
      "We had the pleasure of working with Harihar Papers, a leading exporter of paper rolls and paper dishes. Their exceptional dedication to quality, efficiency, and customer satisfaction was evident throughout the project. We are proud to have been part of their journey in bringing their business to new heights.",
    Img: "/accets/images/harihar-paper.jpg",
    moreimage: [
      "/accets/images/harihar-paper-2.jpg",
      "/accets/images/harihar-paper-1.jpg",
      "/accets/images/harihar-paper-3.jpg",
    ],
    url: "/project/harihar-paper",
    /*     weburl: "https://hariharpapers.in/", */
    weburl: "",
    task: "web development and design",
    Technology: "React, Figma",
    backGround: "rgba(220, 199, 172, 0.4)",
    backGroundDark: "rgba(220, 199, 172, 1)",
  },
  {
    id: 2,
    DetailesId: "EsnafBey",
    name: "EsnafBey",
    type: "Web",
    description:
      "Our collaboration with Esnafbey, an e-commerce platform that prioritizes the needs of wholesalers, was inspiring. Their dedication to providing an efficient and seamless wholesale experience was evident at every stage of the project. We are proud to have played a role in enhancing their platform and supporting their mission.",
    Img: "/accets/images/esnafbey.jpg",
    moreimage: [
      "/accets/images/esnafbey-2.jpg",
      "/accets/images/esnafbey-1.jpg",
      "/accets/images/esnafbey-3.jpg",
    ],
    url: "/project/EsnafBey",
    /* weburl: "https://esnafbey.com.tr/", */
    weburl: "",
    task: "web development and design",
    Technology: "HTML, CSS, Jquery, PHP, Figma",
    backGround: "rgba(206, 132, 141, 0.4)",
    backGroundDark: "rgba(206, 132, 141, 1)",
  },
  {
    id: 3,
    DetailesId: "Gopal-Krishna-tea",
    name: "Gopal Krishna tea",
    type: "Web",
    description:
      "We had the privilege of working with Gopal Krishna Tea, a manufacturer of premium Assam tea. Their commitment to producing high-quality tea while supporting local communities and sustainable practices was evident throughout the project. We are proud to have contributed to their mission of delivering the best tea from Assam to the world.",
    Img: "/accets/images/goplKrisnaTea.jpg",
    moreimage: [
      "/accets/images/goplKrisnaTea-2.jpg",
      "/accets/images/goplKrisnaTea-1.jpg",
      "/accets/images/goplKrisnaTea-3.jpg",
    ],
    url: "/project/Gopal-Krishna-tea",
    /*  weburl: "https://gopalkrishnatea.com/", */
    weburl: "",
    task: "web development and design",
    Technology: "React-js, Figma",
    backGround: "rgba(165, 207, 135, 0.4)",
    backGroundDark: "rgba(165, 207, 135, 1)",
  },
  /*   {
    id: 4,
    DetailesId: "Maccem",
    name: "Maccem",
    type: "Web design",
    description:
      "We partnered with Maccem, a renowned cement company known for its commitment to producing top-quality cement while promoting sustainable practices and supporting local communities. Their dedication to providing superior products that help build better and stronger infrastructure was truly inspiring. We are proud to have worked alongside Maccem in their mission to make a positive impact on the construction industry and the world.",
    Img: "/accets/images/maccem.jpg",
    moreimage: [
      "/accets/images/maccem-2.png",
      "/accets/images/maccem-1.jpg",
      "/accets/images/maccem-3.jpg",
    ],
    url: "/project/Maccem",
    weburl: "",
    task: "web design",
    Technology: "Figma",
    backGround: "rgba(147,25,43,0.4)",
    backGroundDark: "rgba(147,25,43,1)",
  }, */
  /* {
    id: 5,
    DetailesId: "GreenJewelsLogo",
    name: "Green Jewels",
    type: "Logo design",
    description:
      "We had the pleasure of working with Green Jewels, an indoor plants company, to design their logo. Their commitment to delivering quality products and improving indoor air quality was inspiring. We are proud to have contributed to their mission of bringing nature indoors through our logo design.",
    Img: "/accets/images/green-jawel-logo-2.jpg",
    moreimage: [
      "/accets/images/green-jawel-logo-1.jpg",
      "/accets/images/green-jawel-logo-3.jpg",
    ],
    url: "/project/GreenJewelsLogo",
    weburl: "",
    task: "Logo design",
    Technology: "Adobe Illustrator",
    backGround: "rgba(93,124,54,0.4)",
    backGroundDark: "rgba(93,124,54,1)",
  }, 
  {
    id: 6,
    DetailesId: "MadhukunjFlyer",
    name: "Madhukunj Flyer",
    type: "Screach card Flyer design",
    description:
      "We had the pleasure of designing a flyer for Madhukunj, a company committed to excellence. Our team created a visually striking flyer in Adobe Illustrator that effectively communicated their message and showcased their products. We are proud to have contributed to their marketing efforts with our design skills.",
    Img: "/accets/images/madhukunj-flyer-2.jpg",
    moreimage: [
      "/accets/images/madhukunj-flyer-1.jpg",
      "/accets/images/madhukunj-flyer-3.png",
    ],
    url: "/project/MadhukunjFlyer",
    weburl: "",
    task: "Screach card Flyer design",
    Technology: "Adobe Illustrator",
    backGround: "rgba(216,159,202,0.4)",
    backGroundDark: "rgba(216,159,202,1)",
  },*/
  {
    id: 7,
    DetailesId: "barkshop",
    name: "barkshop",
    type: "Web",
    description:
      "We collaborated with Barkshop, a pet toy store that's dedicated to providing high-quality, unique toys for pets. Our team designed a website that effectively showcased their products and provided a seamless user experience for their customers. We are proud to have contributed to their success with our web development skills.",
    Img: "/accets/images/barkshop.jpg",
    moreimage: [
      "/accets/images/barkshop-2.jpg",
      "/accets/images/barkshop-1.jpg",
      "/accets/images/barkshop-3.jpg",
    ],
    url: "/project/barkshop",
    /*  weburl: "https://barkshop.com/", */
    weburl: "",
    task: "web development",
    Technology: "Shopify",
    backGround: "rgba(207, 240, 252, 0.4)",
    backGroundDark: "rgba(207, 240, 252, 1)",
  },
  /*   {
    id: 8,
    DetailesId: "OmMetalCast",
    name: "Om Metal Cast",
    type: "Logo design",
    description:
      "We collaborated with Om Metal Cast, a company that specializes in providing high-quality metal casting solutions. Our team designed a logo that effectively represented their mission of delivering precision, quality, and innovation. We are proud to have contributed to their success with our logo design.",
    Img: "/accets/images/omcars-logo-2.jpg",
    moreimage: [
      "/accets/images/omcars-logo-1.jpg",
      "/accets/images/omcars-logo-3.jpg",
    ],
    url: "/project/OmMetalCast",
    weburl: "",
    task: "Logo design",
    Technology: "Adobe Illustrator",
    backGround: "rgba(8,53,74,0.4)",
    backGroundDark: "rgba(8,53,74,1)",
  }, */
  {
    id: 9,
    DetailesId: "krushi-agri-export",
    name: "Krushi Agri Export",
    type: "Web",
    description:
      "We collaborated with Krushi Agri Export, a leading exporter of high-quality wheat flour. Their commitment to delivering the best quality product while supporting local farmers and communities was inspiring. We are proud to have played a role in their mission to share the goodness of Indian wheat flour with the world.",
    Img: "/accets/images/krushi-agro-export.jpg",
    moreimage: [
      "/accets/images/krushi-agro-export-2.jpg",
      "/accets/images/krushi-agro-export-1.jpg",
      "/accets/images/krushi-agro-export-3.jpg",
    ],
    url: "/project/krushi-agri-export",
    /*  weburl: "http://krushiagriexport.com/", */
    weburl: "",
    task: "web development and design",
    Technology: "React-js, Figma",
    backGround: "rgba(243, 228, 201, 0.4)",
    backGroundDark: "rgba(243, 228, 201, 1)",
  },

  {
    id: 10,
    DetailesId: "Calico-kitchenware",
    name: "Calico",
    type: "Web",
    description:
      "We had the pleasure of collaborating with Calico Kitchenware, a manufacturer of premium kitchen products. Their dedication to quality, design, and innovation was evident in every product. We are proud to have played a role in showcasing their commitment to providing exceptional kitchen solutions.",
    Img: "/accets/images/calico.jpg",
    moreimage: [
      "/accets/images/calico-2.jpg",
      "/accets/images/calico-1.jpg",
      "/accets/images/calico-3.jpg",
    ],
    url: "/project/Calico-kitchenware",
    /* weburl: "https://www.calicokitchenware.in/", */
    weburl: "",
    task: "web development and design",
    Technology: "React-js, Figma",
    backGround: "rgba(250, 218, 179, 0.4)",
    backGroundDark: "rgba(250, 218, 179, 1)",
  },

  {
    id: 11,
    DetailesId: "movesure",
    name: "Movesure",
    type: "Web",
    description:
      "We had the pleasure of collaborating with Movesure, a reliable and efficient relocation company. Their commitment to providing a stress-free and seamless relocation experience for their clients was impressive. We are proud to have contributed to their mission of making moving homes easier for people.",
    Img: "/accets/images/movesure.jpg",
    moreimage: [
      "/accets/images/movesure-2.jpg",
      "/accets/images/movesure-1.jpg",
      "/accets/images/movesure-3.jpg",
    ],
    url: "/project/movesure",
    /* weburl: "https://movesure.in/new", */
    weburl: "",
    task: "web development and design",
    Technology: "React-js, Figma",
    backGround: "rgba(94, 191, 255, 0.4)",
    backGroundDark: "rgba(94, 191, 255, 1)",
  },
  /* {
    id: 12,
    DetailesId: "GreenJewelFlyer",
    name: "Green Jewel Flyer",
    type: "Flyer design",
    description:
      "We had the pleasure of designing a visually captivating flyer for Green Jewel, an indoor plant company that strives to bring nature indoors. Our team created a stunning flyer in Adobe Illustrator that showcased their products and effectively communicated their message. We are proud to have contributed to their marketing efforts with our design skills.",
    Img: "/accets/images/green-jewel-flyer-2.jpg",
    moreimage: [
      "/accets/images/green-jewel-flyer-1.jpg",
      "/accets/images/green-jewel-flyer-3.jpg",
    ],
    url: "/project/GreenJewelFlyer",
    weburl: "",
    task: "Flyer design",
    Technology: "Adobe Illustrator",
    backGround: "rgba(93,124,54,0.4)",
    backGroundDark: "rgba(93,124,54,1)",
  }, */
  {
    id: 13,
    DetailesId: "Cloud-avent",
    name: "Cloud avent",
    type: "Web design",
    description:
      "Our collaboration with Cloud Avent, a leading cloud service provider, was inspiring. Their dedication to delivering secure, scalable, and reliable cloud solutions was evident throughout the project. We are proud to have played a part in their mission to empower businesses with the benefits of cloud computing.",
    Img: "/accets/images/cloude.jpg",
    moreimage: ["/accets/images/cloude-2.jpg", "/accets/images/cloude-1.jpg"],
    url: "/project/Cloud-avent",
    weburl: "",
    task: "web design",
    Technology: "Figma",
    backGround: "rgba(255, 200, 102, 0.4)",
    backGroundDark: "rgba(255, 200, 102, 1)",
  },
  {
    id: 14,
    DetailesId: "fivestar",
    name: "Fivestar",
    type: "Web",
    description:
      "We had the privilege of collaborating with Fivestar, a manufacturer of high-quality brass metal products. Their commitment to delivering exceptional quality, design, and innovation was evident in every product. We are proud to have played a role in showcasing their mission to provide the best-in-class brass metal products.",
    Img: "/accets/images/fivestar.jpg",
    moreimage: [
      "/accets/images/fivestar-2.jpg",
      "/accets/images/fivestar-1.jpg",
      "/accets/images/fivestar-3.jpg",
    ],
    url: "/project/fivestar",
    /* weburl: "https://www.fivestarmetal.com/", */
    weburl: "",
    task: "web development and design",
    Technology: "React-js, Figma",
    backGround: "rgba(110, 145, 210, 0.4)",
    backGroundDark: "rgba(110, 145, 210, 1)",
  },
  /* {
    id: 15,
    DetailesId: "JankiTechnocastLogo",
    name: "Janki Technocast",
    type: "Logo design",
    description:
      "We collaborated with Om Metal Cast, a company that specializes in providing high-quality metal casting solutions. Our team designed a logo that effectively represented their mission of delivering precision, quality, and innovation. We are proud to have contributed to their success with our logo design.",
    Img: "/accets/images/janki-logo-2.jpg",
    moreimage: [
      "/accets/images/janki-logo-1.jpg",
      "/accets/images/janki-logo-3.jpg",
    ],
    url: "/project/JankiTechnocastLogo",
    weburl: "",
    task: "Logo design",
    Technology: "Adobe Illustrator",
    backGround: "rgba(172,149,117,0.4)",
    backGroundDark: "rgba(172,149,117,1)",
  }, */
];

export default Productfile;
