import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";

function Career() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <motion.div
      variants={container}
      className="contactUs-mai-wrapper"
      initial="hidden"
      animate="show"
    >
      <div className="lines"></div>
      <div className="contactUs-inner">
        <motion.div
          className="how_head"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <p>Make your career enlarge with us.</p>
        </motion.div>
        <div className="headding-contactUs-inner">
          <h1>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Why
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.07 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              you
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.14 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              should
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.21 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              choose
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.28 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Recap
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.35 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Infotech
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.42 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Services
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.49 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              for
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.56 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              needs
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.63 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              your
            </motion.span>
            <span>&nbsp;</span>
            <motion.span
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.7 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              career?
            </motion.span>
            <span>&nbsp;</span>
          </h1>
          <motion.p
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.77 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Choosing us as your career partner in the IT services industry
            offers numerous opportunities for growth and professional
            development. Our company values a collaborative and inclusive work
            environment, and we provide ongoing training and support to ensure
            that our employees have the skills and resources they need to
            succeed. We offer a dynamic and challenging work environment where
            innovation and creativity are encouraged, and there are
            opportunities to work on a variety of exciting projects and
            technologies. Furthermore, we offer competitive compensation
            packages and benefits, and a supportive work-life balance. Join us
            and be part of a team that is dedicated to making a positive impact
            on the world through technology.
          </motion.p>
        </div>
        <div className="contactUs-form-wrapper">
          <div className="form-box-contcatus col">
            <motion.div
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.81 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <label>Your Name</label>
              <input type="text" placeholder="Fullname" />
            </motion.div>
            <motion.div
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.85 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <label>Your Email</label>
              <input type="text" placeholder="Your Email Address" />
            </motion.div>
          </div>
          <div className="form-box-contcatus col">
            <motion.div
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.89 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <label>What services you are interested in?</label>
              <input type="text" placeholder="Services" />
            </motion.div>
            <motion.div
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 0.93 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              <label>Your company name</label>
              <input type="text" placeholder=" Company name" />
            </motion.div>
          </div>
          <motion.div
            className="form-box-contcatus"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.97 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <label>Please tell us about your and your project...</label>
            <textarea placeholder="Please tell" rows="10"></textarea>
          </motion.div>
          <motion.div
            className="form-box-contcatus"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <button className="btn btn-allrecap">Send</button>
          </motion.div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default Career;
