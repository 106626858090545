import React, { useRef } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
function Contactmian() {
  const form = useRef();
  const sendEmail = (e) => {
    if (
      document.getElementById("funame").value !== "" &&
      document.getElementById("email").value !== "" &&
      document.getElementById("subject").value !== "" &&
      document.getElementById("massage").value !== ""
    ) {
      e.preventDefault();
      document.getElementById("contactloddingofbutton").style.display = "block";
      document.getElementById("sendContact").style.display = "none";

      {
        {
          /* this email is setup on recapinfotech@gmail.com on https://dashboard.emailjs.com/*/
        }
      }
      emailjs
        .sendForm(
          "service_b0yl538",
          "template_sc57zsf",
          form.current,
          "2ViIgP_7IluYuMMJy"
        )
        .then(
          (result) => {
            console.log(result.text);
            document.getElementById("funame").value = "";
            document.getElementById("email").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("massage").value = "";
            document.getElementById("contactunsuccessmsg").style.display =
              "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("Contacttsuceesmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("Contacttsuceesmsg").style.display = "none";
            document.getElementById("contactemptyfuild").style.display = "none";
            document.getElementById("contactunsuccessmsg").style.display =
              "block";
            document.getElementById("contactloddingofbutton").style.display =
              "none";
            document.getElementById("sendContact").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("Contacttsuceesmsg").style.display = "none";
      document.getElementById("contactunsuccessmsg").style.display = "none";
      document.getElementById("contactemptyfuild").style.display = "block";
      return false;
    }
  };
  return (
    <div className="mian-contactmain-wrapper">
      <div className="mian-contactmain-wrapper-inner">
        <div className="contact-right-detailes">
          <h1>We are here to help you to grow your business online.</h1>
          <p>
            "Get in touch for top-notch IT services. Contact us today to enhance
            your technology solutions."
          </p>
        </div>
        <div className="contact-form-wrapper">
          <div className="contact-form-wrapper-inner">
            <div className="contact-form-box">
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-box">
                  <label>Name :</label>
                  <input
                    type="text"
                    placeholder="Fullname"
                    name="funame"
                    id="funame"
                  />
                </div>
                <div className="form-box">
                  <label>Email : </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Your Email Address"
                    id="email"
                  />
                </div>
                <div className="form-box">
                  <label>Subject : </label>
                  <input
                    type="text"
                    placeholder="Your Subject"
                    name="subject"
                    id="subject"
                  />
                </div>
                <div className="form-box">
                  <label>Message : </label>
                  <textarea
                    placeholder="Message"
                    rows="5"
                    id="massage"
                    name="massage"
                  ></textarea>
                </div>
                <div className="form-box">
                  <button
                    className="btn btn-allrecap contactsubmit"
                    type="submit"
                  >
                    <span className="sendContact" id="sendContact">
                      Send
                    </span>
                    <i
                      className="fa fa-spinner contactloddingofbutton"
                      id="contactloddingofbutton"
                    ></i>
                  </button>
                  <div style={{ width: "100%" }}>
                    <p className="Contacttsuceesmsg" id="Contacttsuceesmsg">
                      Your Message is sent Successfully
                    </p>
                    <p className="contactunsuccessmsg" id="contactunsuccessmsg">
                      Something went wrong, your message isn't sent. Try
                      again!!!
                    </p>
                    <p className="contactunsuccessmsg" id="contactemptyfuild">
                      You have empty fields, fill them first.
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <motion.div
              className="cur-rounddiv"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 0, opacity: 0 },
              }}
            ></motion.div>
          </div>
        </div>
        <motion.div
          className="cur-squre"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          variants={{
            visible: { scale: 1, opacity: 1 },
            hidden: { scale: 0, opacity: 0 },
          }}
        ></motion.div>
        <motion.div
          className="cur-squre1"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
          variants={{
            visible: { scale: 1, opacity: 1 },
            hidden: { scale: 0, opacity: 0 },
          }}
        ></motion.div>
      </div>
    </div>
  );
}

export default Contactmian;
