import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../components/footer";
import { Link, useLocation } from "react-router-dom";
import Productfile from "./OurWorks";
function Work() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <motion.div
      className="work-wrapper"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <div className="lines"></div>
      <div className="work-wrapper-left">
        <motion.div
          className="how_head"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <p>Our Recent Work.</p>
        </motion.div>
        <h1>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Discover
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.07 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            expertise:
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.14 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            View
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.21 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            our
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.28 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            portfolio
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.35 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            of
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.42 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            successful
          </motion.span>{" "}
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.47 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            projects.
          </motion.span>
        </h1>
        <div className="allworkboxes">
          {Productfile.map((web) => (
            <div className="allworkboxes-inner">
              <motion.div
                className="work_img_container"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                {" "}
                <img src={web.Img} className="work_img" alt="" />
                <Link to={web.url}>
                  <div className="overlay"></div>
                  <div className="work_detailes">
                    <h1>{web.name}</h1>
                    <p>{web.type}</p>
                  </div>
                  <motion.div
                    className="cur-squre"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    variants={{
                      visible: { scale: 1, opacity: 1 },
                      hidden: { scale: 0, opacity: 0 },
                    }}
                    style={{ backgroundColor: web.backGroundDark }}
                  ></motion.div>
                  <motion.div
                    className="cur-squre1"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    variants={{
                      visible: { scale: 1, opacity: 1 },
                      hidden: { scale: 0, opacity: 0 },
                    }}
                  ></motion.div>
                </Link>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default Work;
