import React from "react";

import { motion } from "framer-motion";
import { Link, NavLink } from "react-router-dom";
function Herosection() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const items = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const itemsbox = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const fadein = {
    hidden: { opacity: 0, scale: 1.5 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
      },
    },
  };

  const fadeinlogo = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
        delay: 0.4,
      },
    },
  };
  const btot = {
    hidden: { y: 30, opacity: 0 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 2,
      },
    },
  };
  const Zoomin = {
    hidden: { scale: 0, opacity: 0 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      className="manntopwrapper"
      initial="hidden"
      animate="show"
    >
      <div className="herowrapper">
        <div className="back_herosecimg">
          <div className="main-heading-wrapper">
            <h1>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                Empowering
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                digital
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                transformation
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                with
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                Innovative
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                and
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.6 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                Inspiring
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.7 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                IT
              </motion.span>
              <span>&nbsp;</span>
              <motion.span
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.8 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                solutions.
              </motion.span>
            </h1>
            <motion.p
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.9 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Recap Infotech is an IT services company that specializes in{" "}
              <br />
              providing comprehensive solutions to businesses.
            </motion.p>
            <Link to="/AboutUS">
              <motion.button
                className="btn btn-allrecap"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 1 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                Know about us More
              </motion.button>
            </Link>
          </div>
          <div className="top-middle-herosection">
            <div className="top-middle-herosection-inner">
              <div className="left-side-box">
                <motion.div
                  className="left-fig-box"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.16 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <img src="/accets/images/customer.svg" />
                </motion.div>
                <motion.div
                  className="left-fig-box1"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.16 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <img src="/accets/images/relation.svg" />
                </motion.div>
                <motion.div
                  className="left-fig-box2"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.16 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <img src="/accets/images/procomplate.svg" />
                </motion.div>
                <motion.div
                  className="left-fig-line"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.32 }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 },
                  }}
                >
                  <img src="/accets/images/line-drawn2.svg" />
                </motion.div>
                <motion.div
                  className="left-fig-note"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 1.36 }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: -10, opacity: 0 },
                  }}
                >
                  <h6>Realation</h6>
                  <p>Customer Relationship</p>
                </motion.div>
              </div>
              <div className="right-side-box2">
                <div className="right-top-side-box2">
                  <motion.div
                    className="right-top-fig-box"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.04 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/figma.svg" />
                  </motion.div>
                  <motion.div
                    className="right-top-fig-box1"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.08 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/html.svg" />
                  </motion.div>
                  <motion.div
                    className="right-top-fig-line"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.12 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/line-drawn.svg" />
                  </motion.div>
                  <motion.div
                    className="right-top-fig-note"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.16 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: 10, opacity: 0 },
                    }}
                  >
                    <h6>We do</h6>
                    <p>From Design to Development of services</p>
                  </motion.div>
                </div>
                <div className="right-bottom-side-box2">
                  <motion.div
                    className="right-bottom-fig-box"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.16 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/Strategy.svg" />
                  </motion.div>
                  <motion.div
                    className="right-bottom-fig-box1"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.2 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/Design.svg" />
                  </motion.div>
                  <motion.div
                    className="right-bottom-fig-box2"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.24 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/developmet.svg" />
                  </motion.div>
                  <motion.div
                    className="right-bottom-fig-box3"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.28 }}
                    variants={{
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: 10, opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/procomplate.svg" />
                  </motion.div>
                  <motion.div
                    className="right-bottom-fig-line"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.32 }}
                    variants={{
                      visible: { opacity: 1 },
                      hidden: { opacity: 0 },
                    }}
                  >
                    <img src="/accets/images/line-drawn1.svg" />
                  </motion.div>
                  <motion.div
                    className="right-bottom-fig-note"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 1.36 }}
                    variants={{
                      visible: { x: 0, opacity: 1 },
                      hidden: { x: -10, opacity: 0 },
                    }}
                  >
                    <h6>Flow Of Project</h6>
                    <p>Four step of the project flow.</p>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Herosection;
