import "./App.css";
import Navbar from "./components/navbar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import ScrollButton from "./components/scrollbtn";
import PrivacyPolicy from "./components/PrivacyPolicy";

import Home from "./pages/home";
import ContactUs from "./pages/contactUs";
import Career from "./pages/career";
import Work from "./pages/Work";
import AboutUs from "./pages/aboutUs";
import Services from "./pages/Services";
import TermsConditions from "./components/TermsNConditions";
import ProjectDetailes from "./pages/ProjectDetailes";
import Leads from "./pages/Leads";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exect element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/AboutUS" element={<AboutUs />} />
        </Routes>
        <Routes>
          <Route path="/Services" element={<Services />} />
        </Routes>
        <Routes>
          <Route path="/work" element={<Work />} />
        </Routes>
        <Routes>
          <Route path="/Career" element={<Career />} />
        </Routes>
        <Routes>
          <Route path="/ContactUS" element={<ContactUs />} />
        </Routes>
        <Routes>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Routes>
          <Route path="/TermsConditions" element={<TermsConditions />} />
        </Routes>
        <Routes>
          <Route path="/project/:id" element={<ProjectDetailes />} />
        </Routes>
        <Routes>
          <Route path="/admin/leads" element={<Leads />} />
        </Routes>
      </Router>
      <ScrollButton />
    </>
  );
}

export default App;
