import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Footer from "../components/footer";
import { useLocation } from "react-router-dom";
function AboutUs() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <motion.div
      className="aboutUs-wrapper"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <div className="lines"></div>
      <div className="aboutUs-wrapper-inner">
        <motion.div
          className="how_head"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <p>About Us.</p>
        </motion.div>
        <h1>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Recap
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.06 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Infotech
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.12 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            is an
          </motion.span>
          <span>&nbsp;</span>

          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.18 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            IT services
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.24 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            company
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.3 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            that
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.36 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            specializes
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.42 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            in
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.48 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            providing
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.54 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            comprehensive
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.6 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            solutions
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.66 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            to
          </motion.span>
          <span>&nbsp;</span>
          <motion.span
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.72 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            businesses.
          </motion.span>
        </h1>
        <div className="aboutUs-dtales">
          <motion.p
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.78 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            Recap Infotech was founded in 2023 by a group of experienced IT
            professionals with a passion for helping businesses succeed through
            technology. Our team has over 10 years of experience in the IT
            industry, and we have worked with a wide range of clients across
            various industries. We are dedicated to providing the highest
            quality services to our clients and helping them stay ahead of the
            curve in the ever-changing world of technology.
          </motion.p>
          <div className="twosection-aboutus">
            <div className="twosection-aboutus-headding">
              <div className="twosection-aboutus-headding-middle">
                <motion.div
                  className="twosection-aboutus-headding-inner"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.82 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h2>10+</h2>
                  <p>Experience</p>
                </motion.div>
                <motion.div
                  className="cur-rounddiv"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.82 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                ></motion.div>
              </div>
            </div>
            <div className="twosection-aboutus-detailes">
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.88 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                With a decade of experience in the IT services industry, we have
                built a reputation as a trusted provider of cutting-edge
                solutions that drive business success. Our team of experts
                leverages the latest technologies and innovative thinking to
                deliver customized solutions that meet the unique needs of each
                client. Our commitment to excellence and customer satisfaction
                has earned us a loyal client base, and we are dedicated to
                continuing this legacy of success for years to come. Join us and
                discover how we can help your business reach its full potential
                through technology.
              </motion.p>
            </div>
          </div>
          <div className="twosection-aboutus">
            <div className="twosection-aboutus-headding">
              <div className="twosection-aboutus-headding-middle">
                <motion.div
                  className="twosection-aboutus-headding-inner"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h2>80+</h2>
                  <p>Finished Projects</p>
                </motion.div>
                <motion.div
                  className="cur-rounddiv"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                ></motion.div>
              </div>
            </div>
            <div className="twosection-aboutus-detailes">
              <motion.p
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                With expertise in web development, mobile solutions, WordPress,
                e-commerce, digital marketing, branding, print design, packaging
                design, domain registration, and more, we have a strong
                advantage over our competitors. Our integrated approach and
                cost-efficient solutions have led to our rapid success and
                continued growth.
              </motion.p>
            </div>
          </div>
          <motion.div
            className="image-conttanar-aboutus"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <img src="/accets/images/aboutUspage-img.jpg" alt="" />
          </motion.div>
          <div className="my-visson-mission-section">
            <div className="my-visson-mission-section-inner">
              <div style={{ position: "relative" }}>
                <motion.div
                  className="vision-mission"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <div className="vision-mission-inner">
                    <h3>Our Vission</h3>
                    <p>
                      Empowering individuals and organizations to grow with our
                      innovative IT services.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className="vision-mission-inner-squre"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                ></motion.div>
              </div>
              <div style={{ position: "relative" }}>
                <motion.div
                  className="vision-mission"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <div className="vision-mission-inner">
                    <h3>Our Mission</h3>
                    <p>
                      Striving to set a standard in IT with practical,
                      versatile, secure, and cost-effective solutions.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  className="vision-mission-inner-squre"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                ></motion.div>
              </div>
            </div>
            <div className="Why-Recap">
              <h4>Why Recap Infotech?</h4>
              <div className="Why-Recap-inner">
                <motion.div
                  className="Why-Recap-inner-logo"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                >
                  <div className="Why-Recap-inner-logo-inner">A</div>
                </motion.div>
                <motion.div
                  className="Why-Recap-inner-detailes"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h5>Highly-Skilled and certified resources</h5>
                  <p>
                    "Experienced, certified IT professionals delivering
                    exceptional solutions with precision."
                  </p>
                </motion.div>
              </div>
              <div className="Why-Recap-inner">
                <motion.div
                  className="Why-Recap-inner-logo"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                >
                  <div className="Why-Recap-inner-logo-inner">B</div>
                </motion.div>
                <motion.div
                  className="Why-Recap-inner-detailes"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h5>Easy and seamless communication </h5>
                  <p>
                    "Seamless communication for effective and efficient project
                    delivery."
                  </p>
                </motion.div>
              </div>
              <div className="Why-Recap-inner">
                <motion.div
                  className="Why-Recap-inner-logo"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                >
                  <div className="Why-Recap-inner-logo-inner">C</div>
                </motion.div>
                <motion.div
                  className="Why-Recap-inner-detailes"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h5>Source code authorisation</h5>
                  <p>
                    "Guaranteed source code ownership and confidentiality for
                    clients."
                  </p>
                </motion.div>
              </div>
              <div className="Why-Recap-inner">
                <motion.div
                  className="Why-Recap-inner-logo"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                >
                  <div className="Why-Recap-inner-logo-inner">D</div>
                </motion.div>
                <motion.div
                  className="Why-Recap-inner-detailes"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h5>Save time and money</h5>
                  <p>
                    "Maximize efficiency and minimize costs with our innovative
                    IT solutions."
                  </p>
                </motion.div>
              </div>
              <div className="Why-Recap-inner">
                <motion.div
                  className="Why-Recap-inner-logo"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { scale: 1, opacity: 1 },
                    hidden: { scale: 0, opacity: 0 },
                  }}
                >
                  <div className="Why-Recap-inner-logo-inner">E</div>
                </motion.div>
                <motion.div
                  className="Why-Recap-inner-detailes"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  variants={{
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: 10, opacity: 0 },
                  }}
                >
                  <h5>Flexible timings</h5>
                  <p>
                    "Available flexibly to accommodate clients' work schedules
                    and inquiries."
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default AboutUs;
