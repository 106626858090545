import React from "react";
import { motion } from "framer-motion";
function Customerjourney() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <>
      <motion.div
        className="customerjourney_wrapper"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <div className="howwehelpimgcontainer">
          <div style={{ height: "100%", position: "relative" }}>
            <motion.div
              className="cuteddiv-topleft"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 50, opacity: 0 },
              }}
            >
              <img src="/accets/images/customer-jurney.jpg" />
            </motion.div>
            <motion.div
              className="cur-rounddiv"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 0, opacity: 0 },
              }}
            ></motion.div>
            <motion.div
              className="cur-squreediv"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -50, opacity: 0 },
              }}
            >
              <img src="/accets/images/user.svg" alt="" />
              <div>
                <h6>Client-1</h6>
                <p>customer handling from here is really really good.</p>
              </div>
            </motion.div>
            <motion.div
              className="cur-squreediv1"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.25 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 50, opacity: 0 },
              }}
            >
              <img src="/accets/images/user.svg" alt="" />
              <div>
                <h6>Client-2</h6>
                <p>The workflow of handling the project is adorable.</p>
              </div>
            </motion.div>
          </div>
        </div>
        <div className="how_container">
          <motion.div
            className="how_head"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <p>The customer journey.</p>
          </motion.div>
          <div className="how_head_discreaption">
            <motion.h1
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Customer journey through successful IT projects.
            </motion.h1>
          </div>
          <div className="how_head_discreaption">
            <div className="Why-Recap-inner">
              <motion.div
                className="Why-Recap-inner-logo"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0 },
                }}
              >
                <div className="Why-Recap-inner-logo-inner">1</div>
              </motion.div>
              <motion.div
                className="Why-Recap-inner-detailes"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <h5>Strategy</h5>
                <p>
                  Thorough planning and analysis, clearly defined goals, strong
                  project management, efficient resource allocation, risk
                  mitigation strategies.
                </p>
              </motion.div>
            </div>
            <div className="Why-Recap-inner">
              <motion.div
                className="Why-Recap-inner-logo"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0 },
                }}
              >
                <div className="Why-Recap-inner-logo-inner">2</div>
              </motion.div>
              <motion.div
                className="Why-Recap-inner-detailes"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <h5>Design </h5>
                <p>
                  Visualizing and creating a detailed plan for product
                  functionality, user experience, and technical architecture.
                </p>
              </motion.div>
            </div>
            <div className="Why-Recap-inner">
              <motion.div
                className="Why-Recap-inner-logo"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0 },
                }}
              >
                <div className="Why-Recap-inner-logo-inner">3</div>
              </motion.div>
              <motion.div
                className="Why-Recap-inner-detailes"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <h5>Development</h5>
                <p>
                  Implementing the design, writing code, testing, debugging, and
                  delivering a functional product.
                </p>
              </motion.div>
            </div>
            <div className="Why-Recap-inner">
              <motion.div
                className="Why-Recap-inner-logo"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { scale: 1, opacity: 1 },
                  hidden: { scale: 0, opacity: 0 },
                }}
              >
                <div className="Why-Recap-inner-logo-inner">4</div>
              </motion.div>
              <motion.div
                className="Why-Recap-inner-detailes"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 10, opacity: 0 },
                }}
              >
                <h5>You are Launched</h5>
                <p>
                  Project handover, user training, final testing, customer
                  acceptance, and ongoing maintenance.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Customerjourney;
