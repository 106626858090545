import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./footer";
function TermsConditions() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <div className="homepagecontainer PrivacyPolicywrapper">
        <div className="lines"></div>
        <div className="privercypolicycomtainer">
          <div className="how_head">
            <p>Terms & Conditions</p>
          </div>
          <div className="headding-contactUs-inner">
            <h1>Terms & Conditions of Recap Infotech Services</h1>
          </div>
          <div className="PrivacyPolicy_head_discreaption">
            <h3>Defining the Scope of Work</h3>
            <p>
              We request detailed information and may have multiple sessions to
              fully understand the project. We focus on technical website
              development and only work within the agreed scope. Anything beyond
              the scope will be considered a change request. We do include
              front-end design, also we can convert PSD/figma/xd to HTML or
              create CMS/e-commerce themes with well-defined PSD/figma/xd files.
            </p>

            <h3>Information Collection, Use, and Sharing</h3>
            <p>
              We exclusively own the information collected on this site, which
              we only access through voluntary means such as email or direct
              contact from you. Your information will not be sold or leased to
              any third party.
            </p>
            <p>
              Your information will only be used to respond to you regarding
              your inquiry. We will not disclose it to any outside parties,
              except as necessary to fulfill your request, such as for shipping
              purposes.
            </p>
            <p>
              Unless you ask us not to, we may contact you via email in the
              future to tell you about specials, new products or services, or
              changes to this privacy policy.
            </p>
            <h3>Your Access to and Control Over Information</h3>
            <p>See what data we have about you, if any.</p>
            <p>Change/correct any data we have about you.</p>
            <p>Have us delete any data we have about you.</p>
            <p>Express any concern you have about our use of your data.</p>
            <h3>Agreement for confidentiality and non-disclosure in project</h3>
            <p>
              At Recap Infotech, maintaining client confidentiality, including
              contact and project information, is our top priority. Our clients
              retain full ownership of projects. We will not communicate with
              end customers without our client's permission.
            </p>
            <h3>Quality assurance and bug fixing support</h3>
            <p>
              Recap Infotech ensures quality control with dedicated QA for
              functionality and design testing. Testing is usually done on IE7+,
              Chrome, Firefox, and Safari, with specific device testing
              available upon request for an extra fee. We provide a 2-month bug
              fixing guarantee after project delivery and sign-off. Our team is
              committed to promptly addressing any issues or bugs within the
              scope of work.
            </p>
            <h3>Security</h3>
            <p>
              Your information is protected through various precautions both
              online and offline when submitted sensitively through our website.
            </p>
            <p>
              Sensitive information, like credit card data, is transmitted
              securely to us through encryption. Look for a lock icon in the
              address bar and "https" in the website address to verify security.
            </p>
            <p>
              We not only protect sensitive information through encryption
              online but also secure it offline. Access to personal information
              is limited to employees with a specific job requirement, such as
              billing or customer service. The servers storing such information
              are in a secure environment.
            </p>
            <p>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via email us at{" "}
              <a href="mailto:info@recapinfotech.com">info@recapinfotech.com</a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
