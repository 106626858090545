import React from "react";

import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer_inner_main">
          <div className="footer_topbox">
            <div>
              <div className="footer_head_discreaption">
                <div className="footer_detailes_box">
                  <div className="footer_inner_detailes">
                    <h3>Reach Us Out</h3>
                    <p>
                      Recap infotech Services <br />
                      Rajkot, Gujarat - 360002, India
                    </p>
                    {/* <a href="tel:+91"> +91 11111 00000</a>
                    <a href="mailto:inquiries@recapinfotech.com">
                      inquiries@recapinfotech.com
                    </a> */}
                  </div>
                  <div className="footer_inner_detailes">
                    {/*  <h3> Inquiry</h3>
                    <a href="mailto:hr@recapinfotech.com">
                      hr@recapinfotech.com
                    </a> */}
                    <h3>Career / Work Inquiry</h3>
                    <a href="mailto:info@recapinfotech.com">
                      info@recapinfotech.com
                    </a>
                  </div>
                  <div className="footer_inner_detailes">
                    <h3>Stay In Touch</h3>
                    <div className="socialmedia_container">
                      <a
                        href="https://www.linkedin.com/company/recapinfotech/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/accets/images/linkdin.svg"
                          alt=""
                          className="socialmedia"
                          width="25"
                          height="25"
                        />
                      </a>
                      <a
                        href="https://twitter.com/Recapinfotech"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/accets/images/twitter.svg"
                          className="socialmedia"
                          alt=""
                          width="25"
                          height="25"
                        />
                      </a>
                      {/* <a
                        href="https://www.facebook.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/accets/images/instagram.svg"
                          className="socialmedia"
                          alt=""
                          width="25"
                          height="25"
                        />
                      </a> */}
                    </div>
                  </div>
                  {/* <div className="footer_inner_detailes">
                <h3>For career inquiry</h3>
                <a href="mailto:hr@samakshtrade.com">hr@samakshtrade.com</a>
                <a href="tel:+919327368390">+91 93273 68390</a>
  </div>  */}
                </div>
              </div>
            </div>
          </div>

          <div className="footer_head_discreaption">
            <hr className="hrtage" />
            <div className="bottom_footer">
              <p> © {new Date().getFullYear()}, Recap infotech Services</p>
              <div>
                <NavLink to="/PrivacyPolicy">Privacy Policy</NavLink> |{" "}
                <NavLink to="/TermsConditions">Terms & Conditions</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
