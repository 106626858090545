import React from "react";
import { motion } from "framer-motion";
function WorkWith() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <motion.div
      className="WorkWith-wrapper"
      initial="hidden"
      animate="show"
      variants={container}
    >
      <div className="WorkWith-wrapper-heading">
        <div className="how_container">
          <motion.div
            className="how_head"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <p>We are working with...</p>
          </motion.div>
          <div className="how_head_discreaption">
            <motion.h1
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              We are working with several new and old industries and providing
              happiness to our clients.
            </motion.h1>
          </div>
        </div>
        <div></div>
      </div>
      <div className="WorkWith-wrapper-inner">
        <div className="a123456789" style={{ position: "relative" }}>
          <motion.div
            className="vision-mission"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <div className="vision-mission-inner">
              <h3>Startup</h3>
              <p>
                Recap Infotech provides a reliable foundation for business
                startups, empowering entrepreneurs to turn their innovative
                ideas into reality and reach their destination.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="vision-mission-inner-squre"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { scale: 1, opacity: 1 },
              hidden: { scale: 0, opacity: 0 },
            }}
          ></motion.div>
        </div>
        <div className="a123456789" style={{ position: "relative" }}>
          <motion.div
            className="vision-mission"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <div className="vision-mission-inner">
              <h3>Enterprise</h3>
              <p>
                Accelerate software development growth and improve business
                outcomes with Recap Infotech. Transform your ideas into reality
                with our dedicated IT platform.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="vision-mission-inner-squre"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { scale: 1, opacity: 1 },
              hidden: { scale: 0, opacity: 0 },
            }}
          ></motion.div>
        </div>
        <div className="a123456789" style={{ position: "relative" }}>
          <motion.div
            className="vision-mission"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 10, opacity: 0 },
            }}
          >
            <div className="vision-mission-inner">
              <h3>Agency</h3>
              <p>
                We are proud to support software agency development by providing
                necessary resources to handle their greater workloads with a
                touch of perfection.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="vision-mission-inner-squre"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { scale: 1, opacity: 1 },
              hidden: { scale: 0, opacity: 0 },
            }}
          ></motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default WorkWith;
