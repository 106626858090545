import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import $ from "jquery";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
Modal.setAppElement("#root");
const ScrollButton = () => {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpening, setModalIsOpening] = useState(false);
  const [confirmationmodalIsOpen, setconfirmationModalIsOpen] = useState(false);
  const [confirmationmodalIsOpening, setconfirmationModalIsOpening] =
    useState(false);
  const delay = 5;
  const [step, setStep] = useState(1);
  const [mailesended, setmailesended] = useState(false);
  const [formData, setFormData] = useState({
    designRequiment: "",
    selectNeedOrPlatform: "",
    bussinessType: "",
    name: "",
    whatsappnum: "",
    email: "",
  });
  const location = window.location.pathname;
  /* useEffect(() => {
    if (location === "/") {
      setTimeout(() => setModalIsOpen(true), delay * 450);
      setTimeout(() => setModalIsOpening(true), delay * 470);
    }
  }, [location]); */
  const openModal = () => {
    setModalIsOpen(true);
    setTimeout(() => setModalIsOpening(true), delay * 10);
  };
  const openCofirmationModal = () => {
    setconfirmationModalIsOpen(true);
    setTimeout(() => setconfirmationModalIsOpening(true), delay * 10);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setModalIsOpening(false);
    setStep(1);
    setconfirmationModalIsOpen(false);
    setconfirmationModalIsOpen(false);
    setFormData({
      designRequiment: "",
      selectNeedOrPlatform: "",
      bussinessType: "",
      name: "",
      whatsappnum: "",
      email: "",
    });
    setmailesended(false);
  };
  const closeCofirmationModal = () => {
    setconfirmationModalIsOpen(false);
    setTimeout(() => setconfirmationModalIsOpening(false), delay * 10);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "designRequiment") {
      setFormData({
        selectNeedOrPlatform: "",
        bussinessType: "",
        name: "",
        whatsappnum: "",
        email: "",
      });
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };
  const nextStep = (e) => {
    if (step === 1) {
      if (formData.designRequiment != "") {
        setStep((prevStep) => prevStep + 1);
      } else {
        e.preventDefault();
        setStep(1);
      }
    } else if (step === 2) {
      if (formData.selectNeedOrPlatform != "") {
        setStep((prevStep) => prevStep + 1);
      } else {
        e.preventDefault();
        setStep(2);
      }
    } else if (step === 3) {
      if (formData.bussinessType != "") {
        setStep((prevStep) => prevStep + 1);
      } else {
        e.preventDefault();
        setStep(3);
      }
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const calculateProgress = () => {
    const totalSteps = 4;
    const completedSteps = step - 1;
    return (completedSteps / totalSteps) * 100;
  };
  const progressStyle = {
    width: `${calculateProgress()}%`,
  };
  const form = useRef();

  const sendEmail = (e) => {
    const nameValue = $("#name").val();
    const NumberValue = $("#Number").val();
    const emailValue = $("#emailperson").val();
    if (nameValue !== "" && NumberValue !== "" && emailValue !== "") {
      $("#sendContactasdsad").css({
        display: "none",
      });
      $("#contactloddingofbuttonrequested").css({
        display: "block",
      });
      $("#contactemptyfuildreqested").css({
        display: "none",
      });
      fetch("https://recapinfotech.com/Api/sendmail/requirement.php", {
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status == 201) {
            $("#sendContactasdsad").css({
              display: "block",
            });
            $("#contactloddingofbuttonrequested").css({
              display: "none",
            });
            setStep((prevStep) => prevStep + 1);
            setmailesended(true);
            console.log("Success:", result);
          }
        })
        .catch((error) => {
          setmailesended(false);
          $("#sendContactasdsad").css({
            display: "block",
          });
          $("#contactloddingofbuttonrequested").css({
            display: "none",
          });
          $("#contactunsuccessmsgrequested").css({
            display: "block",
          });
          console.error("Error:", error);
        });
    } else {
      $("#sendContactasdsad").css({
        display: "block",
      });
      $("#contactloddingofbuttonrequested").css({
        display: "none",
      });
      $("#contactemptyfuildreqested").css({
        display: "block",
      });
    }
  };
  return (
    <>
      <motion.div
        className="scrolltotopbtn"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <motion.div
          className="top-tooltips"
          style={{ padding: "0 5px" }}
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <div className="tooltip">
            <button className="Hireus-button" onClick={openModal}>
              <div className="Hireus-button-box">
                <div>
                  <img src="/accets/images/quotes.svg" />
                </div>
              </div>
            </button>
            <span class="tooltiptext">Click to get Quote.</span>
          </div>
        </motion.div>
        <motion.div
          className="top-tooltips"
          style={{ padding: "0 5px" }}
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          <div className="tooltip">
            <a
              href="https://wa.me/9484657901"
              target="_blank"
              className=" whatsapp"
            >
              <div className="Hireus-button-box">
                <i class="fa fa-whatsapp fa-2x "></i>
              </div>
            </a>
            <span class="tooltiptext">Click for chat with us.</span>
          </div>
        </motion.div>
        <div
          className="inner_scroll_btn"
          style={{
            opacity: visible ? "1" : "0",
          }}
          onClick={scrollToTop}
        >
          <div
            className="inner_scroll_arroe"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <i
              className="fa fa-long-arrow-right"
              style={{ fontSize: "16px" }}
            ></i>
          </div>
          <div className="scroltextcontainer">
            <p>Scroll to top</p>
          </div>
        </div>
      </motion.div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Requirement form model"
        className={
          modalIsOpening
            ? "modal-transition-here open"
            : "modal-transition-here"
        }
      >
        <div className="progress-bar-container">
          <div
            className="progress-bar"
            style={progressStyle}
            onClick={openCofirmationModal}
          ></div>
        </div>
        <div className="model-wrapper">
          {mailesended ? (
            <button onClick={closeModal} className="colosebutton">
              &times;
            </button>
          ) : (
            <button onClick={openCofirmationModal} className="colosebutton">
              &times;
            </button>
          )}
          <form className="wrapper-from">
            {step === 1 && (
              <>
                <h2>What is your web design requirement?</h2>
                <div className="form-first-wrapper">
                  <div className="form-first">
                    <input
                      type="radio"
                      id="newWeb"
                      name="designRequiment"
                      value="newWeb"
                      onChange={handleInputChange}
                      checked={formData.designRequiment === "newWeb"}
                    />
                    <label for="newWeb" className="stap1-chackbox">
                      <img src="/accets/images/newWeb.svg" alt="" />
                      <h4>Create a new website</h4>
                    </label>
                  </div>
                  <div className="form-first">
                    <input
                      type="radio"
                      id="newchange"
                      name="designRequiment"
                      value="newChanges"
                      onChange={handleInputChange}
                      checked={formData.designRequiment === "newChanges"}
                    />
                    <label for="newchange" className="stap1-chackbox">
                      <img src="/accets/images/webChanges.svg" alt="" />
                      <h4>Make changes to my currant website</h4>
                    </label>
                  </div>
                </div>
                <button className="continuebutton" onClick={nextStep}>
                  Continue{" "}
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
              </>
            )}
            {step === 2 && formData.designRequiment === "newWeb" && (
              <>
                {" "}
                <h2>What are your website needs?</h2>
                <div className="all-redios-wrapper">
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="websiteNeeds1"
                      name="selectNeedOrPlatform"
                      value="Informative"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "Informative"}
                    />
                    <label
                      for="websiteNeeds1"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        To advertise my bussiness / service <br /> e.g.{" "}
                        <span>Informative website</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="websiteNeeds2"
                      name="selectNeedOrPlatform"
                      value="eCommerce"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "eCommerce"}
                    />
                    <label
                      for="websiteNeeds2"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        To sell my products / services <br /> e.g.{" "}
                        <span>e-Commerce website</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="websiteNeeds3"
                      name="selectNeedOrPlatform"
                      value="other"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "other"}
                    />
                    <label
                      for="websiteNeeds3"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Other</span>
                      </h4>
                    </label>
                  </div>
                </div>
                <div className="next-prevbutton">
                  <button className="continuebutton prev" onClick={prevStep}>
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>{" "}
                    Previous
                  </button>
                  <button className="continuebutton" onClick={nextStep}>
                    Continue{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                {" "}
                <h2>What type of bussiness is this for?</h2>
                <div className="all-redios-wrapper">
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="bussinessType1"
                      name="bussinessType"
                      value="Personal"
                      onChange={handleInputChange}
                      checked={formData.bussinessType === "Personal"}
                    />
                    <label
                      for="bussinessType1"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Personal Project</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="bussinessType2"
                      name="bussinessType"
                      value="Small"
                      onChange={handleInputChange}
                      checked={formData.bussinessType === "Small"}
                    />
                    <label
                      for="bussinessType2"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Small business </span> <br />
                        (1 - 9 employees)
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="bussinessType3"
                      name="bussinessType"
                      value="Medium"
                      onChange={handleInputChange}
                      checked={formData.bussinessType === "Medium"}
                    />
                    <label
                      for="bussinessType3"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Medium business </span>
                        <br />
                        (10 - 29 employees)
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="bussinessType4"
                      name="bussinessType"
                      value="Large"
                      onChange={handleInputChange}
                      checked={formData.bussinessType === "Large"}
                    />
                    <label
                      for="bussinessType4"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Large business </span>
                        <br />
                        (30 - 99 employees)
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="bussinessType5"
                      name="bussinessType"
                      value="other"
                      onChange={handleInputChange}
                      checked={formData.bussinessType === "other"}
                    />
                    <label
                      for="bussinessType5"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Other</span>
                      </h4>
                    </label>
                  </div>
                </div>
                <div className="next-prevbutton">
                  <button className="continuebutton prev" onClick={prevStep}>
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>{" "}
                    Previous
                  </button>
                  <button className="continuebutton" onClick={nextStep}>
                    Continue{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </div>
              </>
            )}
            {step === 4 && (
              <>
                {" "}
                <h2>Give us your details through which we can connect you!</h2>
                <div className="connect-information">
                  <div className="form-box-contcatus">
                    <label for="name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleInputChange}
                      id="name"
                      placeholder="Your Full Name*"
                    />
                  </div>
                  <div className="form-box-contcatus">
                    <label for="Number">Your Contact Number</label>
                    <input
                      type="text"
                      name="whatsappnum"
                      id="Number"
                      onChange={handleInputChange}
                      placeholder="Mobile Number*"
                    />
                  </div>
                  <div className="form-box-contcatus">
                    <label for="emailperson">Your Email</label>
                    <input
                      type="text"
                      name="email"
                      id="emailperson"
                      onChange={handleInputChange}
                      placeholder="Your Email*"
                    />
                  </div>
                </div>
                <div className="next-prevbutton">
                  <button className="continuebutton prev" onClick={prevStep}>
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>{" "}
                    Previous
                  </button>
                  <button
                    className="continuebutton"
                    type="button"
                    onClick={sendEmail}
                  >
                    <span className="sendContact" id="sendContactasdsad">
                      Submit
                    </span>
                    <i
                      className="fa fa-spinner contactloddingofbutton"
                      id="contactloddingofbuttonrequested"
                    ></i>
                  </button>
                </div>
                <div style={{ width: "100%" }}>
                  <p
                    className="contactunsuccessmsg"
                    id="contactunsuccessmsgrequested"
                  >
                    Something went wrong, your message isn't sent. Try again!!!
                  </p>
                  <p
                    className="contactunsuccessmsg"
                    id="contactemptyfuildreqested"
                  >
                    You have empty fields, fill them first.
                  </p>
                </div>
              </>
            )}
            {step === 5 && formData.designRequiment === "newWeb" && (
              <>
                <div className="on-success-submition-box">
                  <div className="success-img">
                    <img src="/accets/images/confirm-order.gif" />
                  </div>
                  <h3>Thank you!</h3>
                  <p>
                    We have received your request for a new website, we will
                    connect you as soon as possible
                  </p>
                </div>
              </>
            )}
            {step === 2 && formData.designRequiment === "newChanges" && (
              <>
                {" "}
                <h2>Which platform is your website built on?</h2>
                <div className="all-redios-wrapper">
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="platform1"
                      name="selectNeedOrPlatform"
                      value="notSure"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "notSure"}
                    />
                    <label
                      for="platform1"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>I'm not sure</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="platform2"
                      name="selectNeedOrPlatform"
                      value="Custom"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "Custom"}
                    />
                    <label
                      for="platform2"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Custom built</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="platform3"
                      name="selectNeedOrPlatform"
                      value="Wordpress"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "Wordpress"}
                    />
                    <label
                      for="platform3"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Wordpress</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="platform4"
                      name="selectNeedOrPlatform"
                      value="Shopify"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "Shopify"}
                    />
                    <label
                      for="platform4"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Shopify</span>
                      </h4>
                    </label>
                  </div>
                  <div className="radio-container">
                    <input
                      type="radio"
                      id="platform5"
                      name="selectNeedOrPlatform"
                      value="Wix"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "Wix"}
                    />
                    <label
                      for="platform5"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Wix</span>
                      </h4>
                    </label>
                  </div>

                  <div className="radio-container">
                    <input
                      type="radio"
                      id="websiteNeeds3"
                      name="selectNeedOrPlatform"
                      value="other"
                      onChange={handleInputChange}
                      checked={formData.selectNeedOrPlatform === "other"}
                    />
                    <label
                      for="websiteNeeds3"
                      className="selectNeedOrPlatform-chackbox"
                    >
                      <div className="radio-showing-container">
                        <div className="radio-showing-box">
                          <div className="radio-showing-box-inner"></div>
                        </div>
                      </div>
                      <h4>
                        <span>Other</span>
                      </h4>
                    </label>
                  </div>
                </div>
                <div className="next-prevbutton">
                  <button className="continuebutton prev" onClick={prevStep}>
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>{" "}
                    Previous
                  </button>
                  <button className="continuebutton" onClick={nextStep}>
                    Continue{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>
                </div>
              </>
            )}
            {step === 5 && formData.designRequiment === "newChanges" && (
              <>
                <div className="on-success-submition-box">
                  <div className="success-img">
                    <img src="/accets/images/confirm-order.gif" />
                  </div>
                  <h3>Thank you!</h3>
                  <p>
                    We have received your request for some changes on your
                    website, we will connect you as soon as possible
                  </p>
                </div>
              </>
            )}
          </form>
        </div>
      </Modal>
      <Modal
        isOpen={confirmationmodalIsOpen}
        contentLabel="confirmation Modal"
        className={
          confirmationmodalIsOpening
            ? "modal-transition-here-second open"
            : "modal-transition-here-second "
        }
      >
        <div className="model-wrapper">
          <h2>Are you sure that you want to leave?</h2>
          <p className="text-center">
            We're asking a few questions so we can find you the right pros, and
            send you quotes fast and free!
          </p>
          <div className="next-prevbutton">
            <button className="continuebutton prev" onClick={closeModal}>
              Quit
            </button>
            <button className="continuebutton" onClick={closeCofirmationModal}>
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScrollButton;
