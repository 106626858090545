import React from "react";

function Leads() {
  return (
    <div>
      <div>jdhsgfdhsg</div>
    </div>
  );
}

export default Leads;
