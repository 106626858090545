import React from "react";

import { motion } from "framer-motion";
function Howwehelp() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  return (
    <>
      <motion.div
        className="how_wrapper"
        initial="hidden"
        animate="show"
        variants={container}
      >
        <div className="how_container">
          <motion.div
            className="how_head"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
          >
            <p>How we help.</p>
          </motion.div>
          <div className="how_head_discreaption">
            <motion.h1
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Revolutionizing businesses through cutting-edge IT solutions.
            </motion.h1>
          </div>
          <div className="how_head_discreaption">
            <motion.p
              className="textformotion"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              As a leading IT solutions company, we are dedicated to helping
              businesses achieve their goals through the use of cutting-edge
              technology. We offer customized solutions tailored to each
              client's unique needs, and our experienced professionals work
              closely with clients to understand their challenges and provide
              the most effective solutions.
            </motion.p>
            <motion.p
              className="textformotion"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 10, opacity: 0 },
              }}
            >
              Whether it's modernizing legacy systems, improving efficiency, or
              launching new digital initiatives, we are committed to delivering
              results that drive business success.
            </motion.p>
          </div>
        </div>
        <div className="howwehelpimgcontainer">
          <div style={{ height: "100%", position: "relative" }}>
            <motion.div
              className="cuteddiv-topleft"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 50, opacity: 0 },
              }}
            >
              <img src="/accets/images/whoweare.jpg" />
            </motion.div>
            <motion.div
              className="cur-rounddiv"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 0, opacity: 0 },
              }}
            ></motion.div>
            <motion.div
              className="cur-squreediv"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -50, opacity: 0 },
              }}
            ></motion.div>
            <motion.div
              className="cur-squreediv1"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.25 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -50, opacity: 0 },
              }}
            ></motion.div>
            <motion.div
              className="cur-squreediv2"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -50, opacity: 0 },
              }}
            ></motion.div>
            <motion.div
              className="cur-squreediv3"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.35 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -50, opacity: 0 },
              }}
            ></motion.div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Howwehelp;
